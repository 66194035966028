/**
 * Orders reducer
 * It contains all the order related reducers
 */

import { Order } from '@bff/models/og/order'

import t from './actionTypes'

export const defaultState = {
  feedback: {
    rating: 0,
    show: false,
    submitted: false,
    text: ''
  },
  firstTimeLoaded: false,
  // Assume order has not yet been assigned to a driver.
  // If the order is not new (more than 7 seconds old),
  // then this will immediately be set to true - otherwise,
  // this will update at 7 seconds after the order is created.
  hasBeenAssignedToDriver: false,
  isFetching: false,
  order: <Order>{
    id: 0,
    paymentMethod: 0,
    createdAt: '',
    createdBy: {},
    deliveredAt: null,
    delivery: {
      cityName: '',
      estimatedDeliveryDateTime: null,
      estimatedDeliveryTime: 0,
      location: {},
      shortAddress: '',
      zipCode: ''
    },
    priceInfo: {},
    prices: {},
    products: [],
    taxInfo: [],
    chargeDescriptor: ''
  },
  showOrderDetails: false
}

const orders = (state = defaultState, action) => {
  switch (action.type) {
    case t.RESET_ORDER:
      return defaultState

    case t.TRANSITION_TO_ASSIGNING_DRIVER:
      return {
        ...state,
        hasBeenAssignedToDriver: true
      }

    case t.REQUEST_ORDER:
      return {
        ...state,
        isFetching: true
      }

    case t.RECEIVE_ORDER:
      return {
        ...state,
        isFetching: false,
        firstTimeLoaded: true,
        order: action.order
      }

    case t.RATE_ORDER:
      return {
        ...state,
        feedback: {
          ...state.feedback,
          rating: action.rating,
          submitted: false
        }
      }

    case t.SUBMIT_ORDER_FEEDBACK:
      return {
        ...state,
        feedback: {
          ...state.feedback,
          submitted: true
        }
      }

    case t.SET_FEEDBACK_TEXT:
      return {
        ...state,
        feedback: {
          ...state.feedback,
          text: action.text
        }
      }

    case t.TOGGLE_SHOW_ORDER_DETAILS:
      return {
        ...state,
        showOrderDetails: !state.showOrderDetails
      }

    case t.SET_CHARGE_DESCRIPTOR:
      return {
        ...state,
        chargeDescriptor: action.payload
      }

    // Action only fired to trigger analytics tracking
    case t.VIEW_ORDER:
      return state

    default:
      return state
  }
}

export default orders
